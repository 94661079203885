import React from "react"

import { Layout } from "../components"
import "./agreement.css"
import "./main.css"

const Agreement = () => {
  return (
    <Layout>
      <div className="agreement__wrapper lh-copy animation">
        <h1 className="f1-l f2-m f3">Публичная оферта</h1>
        <p>
          Товарищество с ограниченной ответственностью «OKauto»,
          именуемое в дальнейшем «OKauto», предлагает физическим лицам,
          зарегистрированным в мобильном приложении «OKauto» (далее мобильное
          приложение «OKauto»), именуемым в дальнейшим «Пользователь»
          воспользоваться сервисом OKauto, далее совместно именуемые «Стороны».
        </p>
        <br />
        <p>
          Настоящее предложение в соответствии со статьей 395 Гражданского
          Кодекса Республики Казахстан является публичной офертой (далее –
          Пользовательское соглашение), полным и безоговорочным принятием
          (акцептом) условий которой в соответствии со статьей 396 Гражданского
          кодекса Республики Казахстан считается осуществление Пользователем
          конклюдентных действий – использование мобильного приложения «OKauto»
          и/или осуществление регистрации в мобильном приложении «OKauto».
        </p>

        <h2 className="f2-l f3-m f4">Термины и определения</h2>

        <p>
          • Пользователь – физическое лицо, использующее мобильное приложение
          «OKauto» и/или зарегистрированное в мобильном приложении «OKauto»;
          <br />
          • Сбор Персональных данных – действия, направленные на получение
          Персональных данных; <br />• Обработка Персональных данных – действия,
          направленные на накопление, хранение, изменение, дополнение,
          использование, распространение, обезличивание, блокирование и
          уничтожение Персональных данных.
        </p>

        <h2 className="f2-l f3-m f4">Основные положения:</h2>

        <p>
          1. Совершая действия по акцепту Пользовательского соглашения,
          Пользователь подтверждает свою правоспособность и дееспособность, а
          также свое законное право вступать в договорные отношения.
        </p>
        <p>
          2. Акцепт Пользовательского соглашения означает, что Пользователь
          согласен со всеми положениями настоящего предложения, и равносилен
          заключению Пользовательского соглашения и всех приложений к нему. В
          связи с вышеизложенным, внимательно прочитайте текст Пользовательского
          соглашения и Приложений к нему (условий, размещенных в мобильном
          приложении «OKauto»). Если Вы не согласны с каким-либо пунктом
          Пользовательского соглашения, OKauto предлагает Вам отказаться от
          Акцепта Пользовательского соглашения.
        </p>
        <p>
          3 Предмет и условия Пользовательского соглашения устанавливаются в
          соответствующем Приложении к нему (условий, размещенных в мобильном
          приложении «OKauto»). Приложения являются неотъемлемой частью
          Пользовательского соглашения. Все условия, содержащиеся в Приложениях
          к Пользовательскому соглашению, являются условиями Пользовательского
          соглашения.
        </p>
        <p>
          4. При использовании сервиса может быть представлено содержание,
          созданное и/или загруженное третьими лицами. Последние несут за него
          полную ответственность.
        </p>

        <h2 className="f2-l f3-m f4">Использование сервиса OKauto:</h2>

        <p>
          5. OKauto имеет право заблокировать аккаунт Пользователя в следующих
          случаях:
          <br />
          • нарушения Пользователем законодательства Республики Казахстан,
          условий, размещенных в мобильном приложении «OKauto»;
          <br />
          • мошеннических действий, попытках выполнить платеж по украденным
          данным банковских карт, недобросовестных манипуляций;
          <br />
          • технического сбоя и в иных случаях. <br />
        </p>
        <p>
          6. Не допускается получение Пользователем либо третьими лицами или
          передача Пользователем третьим лицам информации о сервисе с
          использованием автоматических программных и технических средств
          (парсинг), в том числе с целью предоставления такой информации на
          каких-либо иных интернет ресурсах, а также в каком-либо ином
          программном обеспечении, включая мобильные приложения.
        </p>

        <h2 className="f2-l f3-m f4">
          Регистрация, конфиденциальность и защита персональных данных:
        </h2>

        <p>
          7. Пользователь предоставляет OKauto согласие на Сбор Персональных
          данных, Обработку Персональных данных и передачу третьим лицам и
          согласен с тем, что после прохождения процедуры регистрации в
          мобильном приложении «OKauto» на его электронный адрес, телефон могут
          направляться письма и сообщения, в том числе рекламного характера.
        </p>
        <p>
          8. OKauto использует персональные данные Пользователя в том числе:
          <br />
          • для регистрации Пользователя в мобильном приложении «OKauto»;
          <br />
          • для выполнения своих обязательств перед Пользователем;
          <br />
          • для оценки и анализа работы мобильного приложения «OKauto»;
          <br />
          • для определения победителей в конкурсах;
          <br />• для проведения анализа и измерения приобретения
          Пользователями, а также их поведения внутри в мобильного приложения
          «OKauto» и т.д.
        </p>
        <p>
          9. Не считается нарушением предоставление OKauto персональных данных в
          соответствии с обоснованными и применимыми требованиями закона,
          агентам и третьим лицам, действующим на основании договора с OKauto,
          для исполнения обязательств перед Пользователем. OKauto вправе
          использовать технологию «Cookies». «Cookies» не содержат
          конфиденциальную информацию и не передаются третьим лицам. OKauto
          получает информацию об ip-адресе посетителя мобильного приложения
          «OKauto». Данная информация не используется для установления личности
          Пользователя, за исключением случаев мошеннических действий
          Пользователя.
        </p>
        <p>
          10. Пользователь несет ответственность за достоверность передаваемых
          OKauto персональных данных.
        </p>

        <p>
          11. Совершая действия по подключению сервиса привязки банковской
          карты, Пользователь подтверждает свое согласие на хранение информации
          третьими лицами, нести самостоятельную полную ответственность за
          использование сервиса привязки банковской карты, все риски, связанные
          с возможным использованием третьими лицами логина, пароля, ответа на
          контрольный вопрос, номера карты, использование автоматической
          авторизации, в том числе при пересылки сообщения полученного на
          электронную почту и иной информации.
        </p>
        <p>
          12. Пользователь дает согласие OKauto на запись телефонных разговоров
          для повышения качества обслуживания и подтверждения устных заявлений
          Пользователя.
        </p>
        <p>
          13. Пользователь дает свое согласие на получение обновленной
          информации, информационных сообщений с последними новостями, новыми
          предложениями, специальными предложениями и объявлениями о продаже, а
          также информации о новостях и предложениях партнеров OKauto
          посредством SMS, электронной почты, а также пуш уведомлений,
          телефонных звонков и других способов коммуникации.
        </p>
        <p>
          14. Пользователь может выбрать параметры рассылки или отказаться от
          нее.
        </p>
        <p>
          15. OKauto не несет ответственности за неполученную прибыль и
          упущенную выгоду, а также за любые косвенные убытки, понесенные
          Пользователем в период использования или не использования сервиса.
        </p>
      </div>
    </Layout>
  )
}

export default Agreement
